import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: null
}

const userInfoSlice = createSlice({
  name: 'userinfo',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload
      localStorage.setItem('userInfo', JSON.stringify(action.payload)) // 存储用户信息到 localStorage'
    },
    clearUserInfo: (state) => {
      state.userInfo = null; // 清除用户信息
    },
  }
})

export const { setUserInfo, clearUserInfo } = userInfoSlice.actions
export default userInfoSlice.reducer