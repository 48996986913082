import instance from '../utils/request'

// 获取用户信息
export const getUserInfo = (params?: any) => {
  return instance({
    url: 'home/get-user-info',
    method: 'GET',
    params,
  })
}

// 获取未读通知
export const getUnreadNotify = (params?: any) => {
  return instance({
    url: 'home/unread-notification',
    method: 'GET',
    params,
  })
}

// 获取所有通知
export const getAllNotify = (params?: any) => {
  return instance({
    url: 'home/all-notification',
    method: 'GET',
    params,
  })
}

// 获取某个id
export const getNotify = (params?: any) => {
  return instance({
    url: 'home/notify',
    method: 'GET',
    params,
  })
}

// ChatGPT
export const getChatGPT = (data: any) => {
  return instance({
    url: 'openai/chatgpt/question',
    method: 'POST',
    data,
  })
}

// 添加消费记录
export const setExpRecord = (data: any) => {
  return instance({
    url: 'exp_record/set_exp_record',
    method: 'POST',
    data,
  })
}

// 获取消费记录
export const getExpRecord = (params: any) => {
  return instance({
    url: 'exp_record/get_exp_record',
    method: 'GET',
    params,
  })
}

// 删除消费记录
export const deleteExp = (data: {exp_id: number}) => {
  return instance({
    url: 'exp_record/delete_exp_record',
    method: 'POST',
    data,
  })
}

// 修改消费记录
export const changeExp = (data: any) => {
  return instance({
    url: 'exp_record/change_exp_record',
    method: 'POST',
    data,
  })
}

// 获取全部人员信息
export const getAllUser = () => {
  return instance({
    url: 'home/get-users-info',
    method: 'GET'
  })
}

// 获取轮播图信息
export const get_carousel_images = () => {
  return instance({
    url: 'home/get-carousel-images',
    method: 'GET'
  })
}

// 搜索用户和作品
export const search = (data: any) => {
  return instance({
    url: 'home/search',
    method: 'POST',
    data,
  })
}

// 转换请求
export const convert = (data: any) => {
  return instance({
    url: 'audio-format/convert-audio',
    method: 'POST',
    data,
    timeout: 50000
  })
}

// 获取转换记录接口
export const formatRecord = () => {
  return instance({
    url: 'audio-format/get-format-record',
    method: 'GET'
  })
}