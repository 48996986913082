import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './routes/index';
import { Provider } from 'react-redux';
import store from './store';
import configs from 'common/configs';
import { getUserInfo } from 'api/home';
// import { useSelector } from 'react-redux';

const App: React.FC = () => {
  // const route = useRoutes(routes)
  // return route
  // const userInfo: any = useSelector((state: any) => state.userInfo)
  const token = localStorage.getItem('FishDreamToken')
  useEffect(() => {
    if (token) {
      getUserInfo().then((res: any) => {
        if (res.status == 200) {
          const ws = new WebSocket(configs.wsHost)
          ws.onopen = () => {
            console.log('Connected to WebSocket server');
            let param = { type: 'identity', userId: res.data.id }
            ws.send(JSON.stringify(param))
          }
          ws.onmessage = (event: any) => {
            console.log('Received message:', event.data);
            console.log(event.data.length);
            // 处理消息
          }
          ws.onclose = (e: any) => {
            console.log(e);
          }
          return () => {
            ws.close()
          }
        }
      })
    }
  }, [token])
  return (
    <BrowserRouter>
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </BrowserRouter>
  );
}

export default App;
