import React from 'react'
import { IconLoading } from '@douyinfe/semi-icons';
const styles:any = {
  width: "100%",
  height: "100vh",
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  color: "#000"
}
const Span:React.FC = () => {
  return (
    <div style={styles}>
      <IconLoading spin size='extra-large' />
      <div style={{color: '#000', fontSize: 22, marginTop: 20 }}>加载中(首次加载可能很慢)......</div>
    </div>
  )
}

export default Span