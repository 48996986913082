import { Toast } from '@douyinfe/semi-ui';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import configs from 'common/configs';

const token = localStorage.getItem("FishDreamToken")
// 创建 Axios 实例
const instance: AxiosInstance = axios.create({
  // baseURL: 'http://192.168.1.9:8888/',
  baseURL: `${configs.serverHost}/`,
  // baseURL: 'http://159.138.57.207:8888/',
  // baseURL: 'http://192.168.1.112:8888/',
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  }
});

// 添加请求拦截器
instance.interceptors.request.use(
  (config: AxiosRequestConfig): any => {
    // 在发送请求之前做些什么
    config.data = JSON.stringify(config.data);
    config.headers = {
      "Content-Type": "application/json",
      'Authorization': token
    };
    return config;
  },
  (error: any): Promise<any> => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    // 对响应数据做些什么
    switch (response.data.status) {
      case 401:
        Toast.error(response.data?.message)
        setTimeout(() => {
          localStorage.removeItem('FishDreamToken')
          window.location.href ='/user/login'
        }, 1000);
        break;
      case 403:
        Toast.error(response.data?.message)
        setTimeout(() => {
          localStorage.removeItem('FishDreamToken')
          window.location.href ='/user/login'
        }, 1000);
        break;
      case 500:
        Toast.error("服务器出错，请联系最帅的开发人员。");
        break;
      default:
        // 默认情况下不需要执行任何操作
        break;
    }
    return response.data;
  },
  (error: any): Promise<any> => {
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

export default instance;
