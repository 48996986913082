// routes.tsx
import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Span from 'components/common/Span';
const HomeLayout = lazy(() => import('pages/Home'))
const Login = lazy(() => import('pages/users/childrens/Login'))
const Register = lazy(() => import('pages/users/childrens/Register'))
const ResetPwd = lazy(() => import('pages/users/childrens/ResetPwd'))
const User = lazy(() => import('pages/users'))
const Chart = lazy(() => import('pages/Exp/ExpensesRecord'))
const ChatGPT = lazy(() => import('pages/ChatGPT'))
const FishDreamEditor = lazy(() => import('pages/FishDreamEditor'))
const Home = lazy(() => import('pages/Home/CenterChildrens'))
const Pixabay = lazy(() => import('pages/Pixabay'))
const Novel = lazy(() => import('pages/Novel'))
const Read = lazy(() => import('pages/Novel/Read'))
const BigScreen = lazy(() => import('pages/BigScreen'))
const AudioFormatConversion = lazy(() => import('pages/AudioFormatConversion'))
const DownloadImg = lazy(() => import('pages/Pixabay/DownloadImg'))
const ChangeUserInfo = lazy(() => import('pages/Setting/Childrens/ChangeUserInfo'))
const UserInfo = lazy(() => import('pages/UserInfo'))
const Resume = lazy(() => import('pages/UserInfo/Resume/Resume'))
const Works = lazy(() => import('pages/Works'))
const Images = lazy(() => import('pages/Works/Images'))
const NotFound = lazy(() => import('components/common/NotFound'))
const InputExp = lazy(() => import('components/InputExp'))
const Setting = lazy(() => import('pages/Setting'))

const AppRoutes = () => {
  const token = localStorage.getItem('FishDreamToken')
  return (
    <div className="App">
      <Suspense
        fallback={
          <Span />
        }
      >
        <Routes>
          <Route path="/" element={token ? <HomeLayout /> : <Navigate to={'/user/login'} />}>
            <Route path='/' element={<Home />} />
            {/* <Route path='chart' element={<Chart />} /> */}
          </Route>
          {/* <Route path='/user' element={token ? <User /> : <Navigate to={'/user/login'} />}> */}
          <Route path='/user' element={<User />}>
            <Route path="login" element={!token ? <Login /> : <Navigate to={'/'} />} />
            <Route path="register" element={!token ? <Register /> : <Navigate to={'/'} />} />
            <Route path="reset-pwd" element={!token ? <ResetPwd /> : <Navigate to={'/'} />} />
          </Route>
          <Route path='/change-user-info' element={token ? <ChangeUserInfo /> : <Navigate to={'/user/login'} />} />
          <Route path='/user-info/:user_id' element={token ? <UserInfo /> : <Navigate to={'/user/login'} />} />
          <Route path='/platform/upload/:user_id' element={token ? <Works /> : <Navigate to={'/user/login'} />} />
          <Route path='/works/images/:work_id/:user_id' element={token ? <Images /> : <Navigate to={'/user/login'} />} />
          <Route path='/picture/pixabay/download/:id' element={token ? <DownloadImg /> : <Navigate to={'/user/login'} />} />
          <Route path='/input_exp' element={token ? <InputExp /> : <Navigate to={'/user/login'} />} />
          <Route path='/chatgpt' element={token ? <ChatGPT /> : <Navigate to={'/user/login'} />} />
          <Route path='/write/:id' element={token ? <FishDreamEditor /> : <Navigate to={'/user/login'} />} />
          <Route path='/novels' element={token ? <Novel /> : <Navigate to={'/user/login'} />} />
          <Route path='/read/:novel_id/:chapter_id' element={token ? <Read /> : <Navigate to={'/user/login'} />} />
          <Route path='/big-screen' element={token ? <BigScreen /> : <Navigate to={'/user/login'} />} />
          <Route path='/audio-format-conversion' element={token ? <AudioFormatConversion /> : <Navigate to={'/user/login'} />} />
          <Route path='/resume' element={token ? <Resume /> : <Navigate to={'/user/login'} />} />
          <Route path='/picture/pixabay' element={token ? <Pixabay /> : <Navigate to={'/user/login'} />} />
          <Route path='chart' element={token ? <Chart /> : <Navigate to={'/user/login'} />} />
          <Route path='setting' element={token ? <Setting /> : <Navigate to={'/user/login'} />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default AppRoutes;
